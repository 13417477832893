import React from "react";
import NavBar from "../Components/navBar";
import "../styles/homePage.css";
import HeroSwiper from "../Components/heroSwiper";

const XtrCalculatorPage = () => {
    return (
        <>
            <NavBar />
            <HeroSwiper />

            <div className="container">
                <div className="slider"></div>
                <div className="section1">
                    <div className="article_wrapper">
                        <h2 className="title">SPECIFYING PROTECTOR®</h2>
                        <p className="paragraph-blue">
                            The Specifying Protector Portal is an online repository for Fielders® product literature,
                            making our product literature accessible whenever and wherever you need.
                        </p>
                        <p className="paragraph-gray">
                            Our products are renowned for their quality and are engineered to Australian standards
                            BS4994-1987 and ASME RPT1. Protector has improved the ease of device installation by
                            eliminating the need for confined space entry and the requirement to access the base of site
                            excavations.
                        </p>
                        <p className="paragraph-gray">
                            Protector products are constructed using the advanced chop hoop filament winding process
                            which ensures circumferential as well as longitudinal strength. Every Protector product has
                            a smooth molded resin rich inner corrosion barrier layer and an external resin barrier.
                            Being manufactured in FRP (Fibre Reinforced Plastic) Protector products are light, easy to
                            handle and easy to instal
                        </p>
                    </div>
                    <div className="video_wrapper">
                        <iframe
                            className="elementor-video"
                            frameBorder="0"
                            allowFullScreen=""
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            title="Video Placeholder"
                            width="640"
                            height="360"
                            src="https://www.youtube.com/embed/XHOmBV4js_E?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fspecifying.protector.com.au&amp;widgetid=1"
                            id="widget2"
                        ></iframe>
                    </div>
                </div>

                <div className="section2">
                    <h2>SPECIFYING Protector ® LATEST RELEASES</h2>
                    <p className="article_white">
                        Specifying Protector ® is being constantly updated with new profiles, technical literature and
                        exclusive tools and resources to help you specify ® range with accuracy and ease. If you have
                        not been granted access to our exclusive content yet, you can request access here.
                    </p>
                    <div className="wrapper-row">
                        <div className="tornato_gpt">
                            <img src="/imgs/FINAL.png" alt="" />
                            <h2>Tornado-GPT</h2>
                            <p className="article">
                                Generate specification documentation with SpeCreator. Ensuring your specifications
                                capture important design, fixing and installation details to protect you and your
                                client’s project needs.
                            </p>
                            <button className="white-button">CLICK HERE</button>
                        </div>
                        <div className="hydroprotector">
                            <img src="/imgs/kingflor-logo-1.webp" alt="" />
                            <h2>Hydroprotector</h2>
                            <p className="article">
                                Protector® is proud to announce the new KingFlor Design Suite is now available for
                                download. Once logged in you can access the software along with other Fielders®
                                exclusive profiles and their design files.
                            </p>
                            <button className="white-button">CLICK HERE</button>
                        </div>
                        <div className="trapIt">
                            <img src="/imgs/fielders-bim-in-screen.webp" alt="" />
                            <h2>Trap IT</h2>
                            <p className="article">
                                Fielders® is pleased to provide access to CAD and Revit files for BIM. Our exclusive
                                profiles and their design files are available for all logged in users and can be
                                accessed from profile pages and the downloads page.
                            </p>
                            <button className="white-button">CLICK HERE</button>
                        </div>
                    </div>
                </div>

                <div className="section3">
                    <img src="/imgs/Protector-Text-Black-1.png" alt="" />
                    <img src="/imgs/capture.png" alt="" />
                    <img src="/imgs/1212112-01-1-e1725476000365.jpg" alt="" />
                    <p className="p1">
                        Protector ® is proud to offer leading and innovative products that Quality control procedures
                        ensure each product is manufactured to specification and post production, each unit is inspected
                        and factory tested to industry standards. ARAMAX®, Fielders FreeForm™ and the six Finesse®
                        profiles are integral parts of iconic structures around Australia, such as the SCG, Adelaide Zoo
                        and Netball Central Australia.
                    </p>
                    <p className="p2">
                        In addition to this, our KingFlor® range of composite steel formwork offers 5 profiles- the
                        largest range in the Australian market. Each of the profiles has been developed to provide
                        optimal flooring solutions, in both concrete and steel frame construction.
                    </p>
                </div>

                <div className="section4">
                    <div className="wrapper0">
                        <img src="/imgs/LOGIN_REV-Nov-18-3.png" alt="" />
                    </div>
                    <div className="wrapper1">
                        <h2>SPECIFYING ® PREMIUM LOGIN ACCESS NOW AVAILABLE</h2>
                        <p>
                            Login access to exclusive Specifying Protector® product literature is available now. Once
                            registered you’ll get full access to literature for our premium products as well as full
                            access to all product design files and details. Not a registered user yet? Register
                        </p>
                        <div className="wrapper2">
                            <span>today to get full access.</span>
                            <button>REGISTER TODAY</button>
                        </div>
                    </div>
                </div>

                <footer>
                    <div className="wrapper1">
                        <img className="img1" src="/imgs/Protector-Text-Black-1-300x25.png" alt="" />
                        <img className="img2" src="/imgs/Saving-Nature-e1725214906424.png" alt="" />
                    </div>

                    <div className="links">
                        <span className="span1">Aout</span>
                        <span className="span2">Guarantee And Warranties</span>
                        <span className="span3">Legals</span>
                        <span className="span4">Contact</span>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default XtrCalculatorPage;
