import React, { createContext, useReducer, useEffect } from "react";
import apiClient from "./apiClient";

const AuthContext = createContext();

const initialState = {
    isAuthenticated: false,
    emailVerified: false,
    user: null,
};
// localStorage.setItem("authState", JSON.stringify(initialState));

// Helper function to load state from localStorage
const loadStateFromLocalStorage = () => {
    const savedState = localStorage.getItem("authState");
    return savedState ? JSON.parse(savedState) : initialState;
};

const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return {
                ...state,
                isAuthenticated: true,
                emailVerified: action.payload.emailVerified,
                user: action.payload.user,
            };
        case "LOGOUT":
            return {
                ...state,
                isAuthenticated: false,
                emailVerified: false,
                user: null,
            };
        default:
            return state;
    }
};

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState, loadStateFromLocalStorage);

    // Save state to localStorage whenever it changes
    useEffect(() => {
        if (state !== undefined && state !== null) {
            localStorage.setItem("authState", JSON.stringify(state));
        }
    }, [state]);

    // useEffect(() => {
    //     const checkAuth = async () => {
    //         try {
    //             const response = await apiClient.get("/api/Account/status");
    //             if (response.data.isAuthenticated) {
    //                 dispatch({
    //                     type: "LOGIN_SUCCESS",
    //                     payload: {
    //                         user: response.data.user,
    //                         emailVerified: response.data.emailVerified,
    //                     },
    //                 });
    //             }
    //         } catch (error) {
    //             console.error("User is not authenticated", error);
    //         }
    //     };
    //     checkAuth();
    // }, []);

    return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
