import React from "react";
import DropdownLoginForm from "../Components/dropdownLoginForm";

const PlayPage = () => {
    return (
        <div>
            <h1>This is a Play Ground</h1>
            <DropdownLoginForm />
        </div>
    );
};

export default PlayPage;
