import React from "react";
import NavBar from "../../Components/navBar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ImageListItem from "@mui/material/ImageListItem";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField, Button } from "@mui/material";
import { useEffect } from "react";

const CaseStudiesPage = () => {
    const images = [
        "ymSvdr_o-modified.png",
        "CYJyflJ8-modified.jpeg",
        "HYD.3000-Install-modified.jpg",
        "Fllvg6T5-modified.png",
        "6ghVojMw-modified.png",
        "v6xK6eKY-modified.jpeg",
        "AUN6rirw-modified.jpeg",
        "WJ4olcCw-modified.jpeg",
        "CFA96B75-B063-4696-BD81-34210A3EABFA-1-modified.jpeg",
        "Lightsveiw-Village-modified.jpg",
        "XTR-in-concrete-chamber-02-Greyscale.jpg",
    ];

    const colorImages = [
        "ymSvdr_o.png",
        "CYJyflJ8.jpeg",
        "HYD.3000-Install.jpg",
        "Fllvg6T5.png",
        "6ghVojMw.png",
        "v6xK6eKY.jpeg",
        "AUN6rirw.jpeg",
        "WJ4olcCw.jpeg",
        "CFA96B75-B063-4696-BD81-34210A3EABFA-1.jpeg",
        "Lightsveiw-Village.jpg",
        "XTR-in-concrete-chamber-02-5.jpg",
    ];

    const titles = [
        "PERRY'S FUEL - RENMARK",
        "PUMA",
        "GANGARRI SOLAR PROJECT - QLD",
        "CLEANAWAY - ERSKINE PARK",
        "ROCKHAMPTON AIRPORT",
        "THE MASON APARTMENT COMPLEX-BELFIELD",
        "UNION QUARTER - SPOTSWOOD",
        "BRUE STREET - MARLESTON",
        "HEATHDALE CHRISTIAN COLLEGE",
        "LIGHTSVIEW VILLAGE SHOPPING CENTRE",
        "EDMONSON PUBLIC SCHOOL",
    ];

    const descriptions = [
        "Protector supplied Perry’s Fuel with a Hydraprotector oil water separator for the Renmark Perry’s Fuel stations.",
        "Puma Energy’s Western Australia project installed an above-ground HydroProtector oil-water separator to improve stormwater protection in a bulk fuel seaport terminal.",
        "Gangarri Solar Project supports Shell’s net-zero emissions goal, with a 120 MW solar farm and a HydroProtector.",
        "Cleanaway’s Erskine Park Transfer Station in Sydney uses the HydroProtector system to manage stormwater pollution and protect the environment.",
        "Rockhampton Airport upgrade utilizes Protector’s StormProtector system to manage stormwater pollution and protect local ecosystems and waterways.",
        "The Mason Apartment Complex in Belfield installed the EnviroProtector stormwater filter to address pollution concerns and protect the environment.",
        "Protector Australia provided a 28,000L dual pump station with an inlet macerator for wastewater management in the Union Quarter.",
        "A new commercial development in South Australia features the installation of a highly efficient EcoProtector GPT, protecting the environment and managing stormwater pollution.",
        "Heathdale Christian College utilizes life Hydrovault tanks designed and manufactured by Protector Australia for stormwater retention and reuse.",
        "The Lightsview Village Shopping Centre utilized the StormProtector to manage stormwater pollution and protect the environment effectively.",
        "Protector Australia provided a successful WSUD solution for Edmondson Park Public School, managing stormwater runoff and enhancing water quality.",
    ];

    const validationSchema = Yup.object({
        name: Yup.string().required("Your name is required"),
        email: Yup.string().email("Invalid email format").required("Your email is required"),
        subject: Yup.string().required("Subject is required"),
        message: Yup.string(),
        verifyCode: Yup.string().required("Verification code is required"),
    });
    const heroImages = ["/imgs/Capturetruck.png", "/imgs/Capture3.png", "/imgs/CaptureRedHoodie.png"];

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [heroImageIndex, setHeroImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setHeroImageIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
        }, 9000); // 3000 ms = 3 seconds

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [heroImages.length]);

    return (
        <>
            <NavBar />

            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    height: "40vh",
                }}
            >
                {heroImages.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Rotating ${index + 1}`}
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "40vh",
                            objectFit: "cover",
                            transition: "opacity 0.5s ease-in-out", // Smooth transition
                            opacity: heroImageIndex === index ? 1 : 0, // Only show current image
                        }}
                    />
                ))}
            </Box>
            <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }}>
                <Grid container spacing={1} sx={{ my: 2, maxWidth: "1200px", mx: "auto" }}>
                    {/* Original grid item */}
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                height: 360,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#006688",
                                overflow: "hidden",
                                cursor: "pointer",
                            }}
                        >
                            <Box
                                sx={{
                                    textAlign: "left",
                                    maxWidth: "80%",
                                    mt: -4,
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "'Karla', sans-serif",
                                        fontSize: "44px",
                                        fontWeight: 600,
                                        lineHeight: "60px",
                                    }}
                                >
                                    PROTECTOR PROJECTS
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Image grid items */}
                    {images.map((src, index) => (
                        <Grid item xs={4} key={index}>
                            <Box
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                                sx={{
                                    height: 360,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                    position: "relative",
                                    "&:hover .hoverContent": {
                                        opacity: 1,
                                    },
                                }}
                            >
                                <img
                                    src={hoveredIndex === index ? `/imgs/${colorImages[index]}` : `/imgs/${src}`}
                                    alt={`Case Study ${index + 1}`}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        transition: "opacity 0.3s ease",
                                    }}
                                />

                                {/* Text box that fades in on hover */}
                                <Box
                                    className="hoverContent"
                                    sx={{
                                        position: "absolute",
                                        bottom: "25px",
                                        width: "100%",
                                        maxWidth: "82%",
                                        padding: 2,
                                        display: "flex",
                                        // alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        color: "#FFFFFF",
                                        opacity: 0,
                                        transition: "opacity 1.3s ease",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "'Roboto', sans-serif",
                                            fontWeight: 600,
                                            fontSize: "30px",
                                            lineHeight: "30px",
                                            opacity: 1,
                                        }}
                                    >
                                        {titles[index % titles.length]} {/* Ensure title wraps */}
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontFamily: "'Roboto', sans-serif",
                                            fontSize: "14px",
                                            mt: 1,
                                            lineHeight: "18px",
                                            color: "#FFFFFF",
                                            fontWeight: 400,
                                            opacity: 1,
                                        }}
                                    >
                                        {descriptions[index % descriptions.length]} {/* Ensure description wraps */}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row", // or "column" for vertical layout
                        justifyContent: "space-between", // adjust as needed
                        gap: 2, // spacing between items
                        mt: 2,
                    }}
                >
                    <Box
                        sx={{
                            flex: 1, // Makes the box take equal space (if multiple items)
                            padding: 2, // optional padding inside each box
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontFamily: "'Neue Haas Unica W06 Regular', sans-serif",
                                fontSize: "30px",
                                fontWeight: 500,
                                lineHeight: "40px",
                                marginBottom: "20px",
                                marginTop: "20px",
                            }}
                        >
                            Enquire with us Today!
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                color: "#00688E",
                                fontFamily: "'Neue Haas Unica W06 Regular', sans-serif",
                                fontSize: "18px",
                                fontWeight: 400,
                            }}
                        >
                            Call
                            <Typography
                                component="span"
                                sx={{
                                    color: "#00688E",
                                    fontFamily: "'Neue Haas Unica W06 Regular', sans-serif",
                                    fontSize: "18px",
                                    fontWeight: 400,
                                    textDecoration: "underline",
                                    paddingLeft: "5px",
                                    cursor: "pointer",
                                }}
                            >
                                1300 585 787
                            </Typography>
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            flex: 1, // Makes the box take equal space
                            padding: 2, // optional padding inside each box
                        }}
                    >
                        <Formik
                            initialValues={{
                                name: "",
                                email: "",
                                subject: "",
                                message: "",
                                verifyCode: "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                // console.log("Form data", values);
                                // Handle form submission
                            }}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography sx={{}}>Your name</Typography>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                fullWidth
                                                sx={{
                                                    padding: "0px",
                                                    width: "395px",
                                                    height: "54px",
                                                    borderRadius: "5px",
                                                    color: "#000000",
                                                    backgroundColor: "#F4F8FA",
                                                    border: "1px solid #e7e7e7",
                                                }}
                                                error={touched.name && Boolean(errors.name)}
                                                helperText={touched.name && errors.name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{}}>Your email</Typography>
                                            <Field
                                                as={TextField}
                                                name="email"
                                                sx={{
                                                    padding: "0px",
                                                    width: "395px",
                                                    height: "54px",
                                                    borderRadius: "5px",
                                                    color: "#000000",
                                                    backgroundColor: "#F4F8FA",
                                                    border: "1px solid #e7e7e7",
                                                }}
                                                fullWidth
                                                error={touched.email && Boolean(errors.email)}
                                                helperText={touched.email && errors.email}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{}}>Subject</Typography>
                                            <Field
                                                as={TextField}
                                                name="subject"
                                                sx={{
                                                    padding: "0px",
                                                    width: "395px",
                                                    height: "54px",
                                                    borderRadius: "5px",
                                                    color: "#000000",
                                                    backgroundColor: "#F4F8FA",
                                                    border: "1px solid #e7e7e7",
                                                }}
                                                fullWidth
                                                error={touched.subject && Boolean(errors.subject)}
                                                helperText={touched.subject && errors.subject}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{}}>Your message (optional)</Typography>
                                            <Field
                                                as={TextField}
                                                name="message"
                                                sx={{
                                                    padding: "0px",
                                                    width: "395px",

                                                    borderRadius: "5px",
                                                    color: "#000000",
                                                    backgroundColor: "#F4F8FA",
                                                    border: "1px solid #e7e7e7",
                                                }}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                error={touched.message && Boolean(errors.message)}
                                                helperText={touched.message && errors.message}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="outlined"
                                                fullWidth
                                                sx={{
                                                    backgroundColor: "#F4F8FA",
                                                    padding: "0px",
                                                    // width: "395px",
                                                    height: "54px",
                                                    borderRadius: "5px",
                                                    color: "#000000",
                                                    borderColor: "#e7e7e7",
                                                    "&:hover": {
                                                        borderColor: "#c4c4c4",
                                                    },
                                                }}
                                            >
                                                Click here to verify your Email
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{}}>Verify Code (required)</Typography>

                                            <Field
                                                as={TextField}
                                                name="verifyCode"
                                                sx={{
                                                    padding: "0px",
                                                    width: "395px",
                                                    height: "54px",
                                                    borderRadius: "5px",
                                                    color: "#000000",
                                                    backgroundColor: "#F4F8FA",
                                                    border: "1px solid #e7e7e7",
                                                }}
                                                fullWidth
                                                error={touched.verifyCode && Boolean(errors.verifyCode)}
                                                helperText={touched.verifyCode && errors.verifyCode}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{}}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    fontSize: "15px",
                                                    borderRadius: "5px",
                                                    padding: "12px 35px",
                                                    color: "#ffffff",
                                                    backgroundColor: "#00688E",
                                                    "&:hover": {
                                                        backgroundColor: "#005f7e", // Optional: change color on hover
                                                    },
                                                    mt: 2,
                                                    width: "116.917px",
                                                    height: "46.5px",
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Container>

            <footer>
                <div className="wrapper1">
                    <img className="img1" src="/imgs/Protector-Text-Black-1-300x25.png" alt="Protector Text Black" />
                    <img className="img2" src="/imgs/Saving-Nature-e1725214906424.png" alt="Saving Nature" />
                </div>

                <div className="links">
                    <span className="span1">Aout</span>
                    <span className="span2">Guarantee And Warranties</span>
                    <span className="span3">Legals</span>
                    <span className="span4">Contact</span>
                </div>
            </footer>
        </>
    );
};

export default CaseStudiesPage;
