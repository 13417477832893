import React, { useEffect, useState } from "react";
import apiClient from "../Helpers/apiClient";
import "../styles/customStyles.css";
import XtrCalculatorImageDisplayer from "./XtrCalculatorImageDisplayer";

import {
    TextField,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";

const XtrCalculator = () => {
    const [formData, setFormData] = useState({
        filterModel: "",
        baseType: "",
        noOfFilters: "",
        filterChamberType: "",
        length: "",
        width: "",
        diameter: "",
    });
    const [cylindricalSurfaceArea, setCylindricalSurfaceArea] = useState(null);
    const [rectangularSurfaceArea, setRectangularSurfaceArea] = useState(null);
    const [weirHeight, setWeirHeight] = useState(null);
    //area calculation result
    const [resultData, setResultData] = useState(null);
    const [showMusicTable, setShowMusicTable] = useState(false); // Control visibility of the table

    const hardcodedData = {
        lowFlowBypass: 0,
        highFlowBypass: 100,
        exfiltrationRate: 0,
        evaporativeLoss: 0,
        weirWidth: 2,
    };

    // Clean the formData before sending the request
    const cleanFormData = (data) => {
        const cleanedData = { ...data };

        // List of fields to convert to -1 if empty
        const fieldsToConvert = ["length", "width", "diameter", "noOfFilters"];

        fieldsToConvert.forEach((field) => {
            if (cleanedData[field] === "") {
                cleanedData[field] = -1; // Convert empty string to -1
            } else {
                cleanedData[field] = parseFloat(cleanedData[field]); // Ensure valid number
            }
        });

        return cleanedData;
    };

    //for filter model table calculation
    const calculateData = (filterModel) => {
        const isXTR500 = filterModel === "XTR.500";

        return {
            cartridgeFlowRate: isXTR500 ? 1.6 : 0.96,
            cartridgeHeight: isXTR500 ? 0.5 : 0.3,
            physicalFilterHeight: isXTR500 ? 0.75 : 0.55,
            totalHeightOnFalseFloor: isXTR500 ? 0.8 : 0.6,
            minimumWeirHeightOnFalseFloor: isXTR500 ? 0.85 : 0.65,
            totalHeightOnDWVBase: isXTR500 ? 0.92 : 0.72,
            minimumWeirHeightOnDWVBase: isXTR500 ? 0.97 : 0.77,
        };
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update form data for any input field
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        const { filterModel, baseType } = {
            ...formData,
            [name]: value, // update the latest changed value
        };

        // Reset the relevant fields based on filter chamber type
        if (name === "filterChamberType") {
            if (value === "Cylindrical") {
                setFormData((prevData) => ({
                    ...prevData,
                    length: "",
                    width: "",
                }));
                setRectangularSurfaceArea(null); // Reset rectangular surface area
            } else if (value === "Rectangular") {
                setFormData((prevData) => ({
                    ...prevData,
                    diameter: "",
                }));
                setCylindricalSurfaceArea(null); // Reset cylindrical surface area
            }
        }

        if (filterModel && baseType) {
            // Weir height calculation logic
            const calculatedWeirHeight =
                filterModel === "XTR.500"
                    ? baseType === "DWV"
                        ? 0.97
                        : 0.85
                    : baseType === "DWV"
                    ? 0.77
                    : 0.65;

            setWeirHeight(calculatedWeirHeight);
        } else {
            setWeirHeight(null); // If one dropdown is not selected, reset the weirHeight
        }
    };

    //filter model tabe data
    const tableData = formData.filterModel ? calculateData(formData.filterModel) : null;

    // Table structure
    const rows = [
        { label: "Cartridge Flow Rate", key: "cartridgeFlowRate" },
        { label: "Cartridge Height", key: "cartridgeHeight" },
        { label: "Physical Filter Height", key: "physicalFilterHeight" },
        {
            label: "Total Height on False Floor",
            key: "totalHeightOnFalseFloor",
        },
        {
            label: "Minimum Weir Height on False Floor",
            key: "minimumWeirHeightOnFalseFloor",
        },
        { label: "Total Height on DWV Base", key: "totalHeightOnDWVBase" },
        {
            label: "Minimum Weir Height on DWV Base",
            key: "minimumWeirHeightOnDWVBase",
        },
    ];

    // Calculate cylindrical surface area when diameter or filter type changes
    useEffect(() => {
        if (formData.diameter === "") {
            setCylindricalSurfaceArea(null);
        }
        if (formData.filterChamberType === "Cylindrical" && formData.diameter) {
            const diameter = parseFloat(formData.diameter);
            if (!isNaN(diameter)) {
                const vaultSurfaceAreaCylindrical = Math.PI * Math.pow(diameter / 2, 2);
                setCylindricalSurfaceArea(vaultSurfaceAreaCylindrical.toFixed(2));
            } else {
                setCylindricalSurfaceArea(null);
            }
        }
    }, [formData.diameter, formData.filterChamberType]);

    // Calculate rectangular surface area when length, width, or filter type changes
    useEffect(() => {
        if (formData.width === "" || formData.length === "") {
            setRectangularSurfaceArea(null);
        }
        if (formData.filterChamberType === "Rectangular" && formData.length && formData.width) {
            const length = parseFloat(formData.length);
            const width = parseFloat(formData.width);

            if (!isNaN(length) && !isNaN(width)) {
                const rectangularArea = length * width;
                setRectangularSurfaceArea(rectangularArea.toFixed(2));
            } else {
                setRectangularSurfaceArea(null);
            }
        }
    }, [formData.length, formData.width, formData.filterChamberType]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const cleanedData = cleanFormData(formData);
        try {
            const response = await apiClient.post("/api/calculator/calculate", cleanedData);
            setResultData(response.data);
            setShowMusicTable(true); // Show the table after data is fetched
        } catch (error) {
            console.error("Error calculating data", error);
        }
    };

    return (
        <Grid
            container
            spacing={2}
            sx={{ padding: 2, backgroundColor: "#eef2f7", height: "auto", minHeight: "100vh" }}
        >
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    sx={{
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                    }}
                >
                    {/* Header */}
                    <Box
                        sx={{
                            backgroundColor: "#2a3a5b",
                            padding: 2,
                            color: "#fff",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            XtreamFilter MUSIC Calculator
                        </Typography>
                    </Box>
                    <Box sx={{ padding: 2 }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            marginBottom: 1,
                                        }}
                                    >
                                        Filter Model
                                    </Typography>
                                    <FormControl fullWidth required>
                                        {/* <InputLabel>Filter Model</InputLabel> */}
                                        <Select
                                            name="filterModel"
                                            value={formData.filterModel}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="XTR.500">XTR.500</MenuItem>
                                            <MenuItem value="XTR.300">XTR.300</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            marginBottom: 1,
                                        }}
                                    >
                                        Base Type
                                    </Typography>
                                    <FormControl fullWidth required>
                                        {/* <InputLabel>Base Type</InputLabel> */}
                                        <Select
                                            name="baseType"
                                            value={formData.baseType}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="DWV">DWV</MenuItem>
                                            <MenuItem value="FRP">FRP</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {weirHeight !== null && (
                                    <Grid item xs={12}>
                                        <Typography variant="h6" color="#a6a6a6">
                                            Weir Height: {weirHeight} meters
                                        </Typography>
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            marginBottom: 1,
                                        }}
                                    >
                                        No of Filters
                                    </Typography>
                                    <TextField
                                        name="noOfFilters"
                                        value={formData.noOfFilters}
                                        onChange={handleChange}
                                        type="number"
                                        fullWidth
                                        required
                                        InputProps={{ className: "no-arrows" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            marginBottom: 1,
                                        }}
                                    >
                                        Filter Vault Type
                                    </Typography>
                                    <FormControl fullWidth required>
                                        {/* <InputLabel>Filter Chamber Type</InputLabel> */}
                                        <Select
                                            name="filterChamberType"
                                            value={formData.filterChamberType}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="Rectangular">Rectangular</MenuItem>
                                            <MenuItem value="Cylindrical">Cylindrical</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {formData.filterChamberType === "Cylindrical" && (
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Diameter"
                                            name="diameter"
                                            value={formData.diameter}
                                            onChange={handleChange}
                                            type="number"
                                            fullWidth
                                            required
                                            InputProps={{
                                                className: "no-arrows",
                                            }}
                                        />
                                    </Grid>
                                )}

                                {formData.filterChamberType === "Rectangular" && (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Length"
                                                name="length"
                                                value={formData.length}
                                                onChange={handleChange}
                                                type="number"
                                                fullWidth
                                                required
                                                InputProps={{
                                                    className: "no-arrows",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Width"
                                                name="width"
                                                value={formData.width}
                                                onChange={handleChange}
                                                type="number"
                                                fullWidth
                                                required
                                                InputProps={{
                                                    className: "no-arrows",
                                                }}
                                            />
                                        </Grid>
                                        {/* Display the calculated surface area */}
                                    </>
                                )}

                                {cylindricalSurfaceArea !== null && (
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Surface Area: {cylindricalSurfaceArea} square meters
                                        </Typography>
                                    </Grid>
                                )}

                                {rectangularSurfaceArea !== null && (
                                    <Grid item xs={12}>
                                        <Typography variant="h6" color="#a6a6a6">
                                            Rectangular Surface Area: {rectangularSurfaceArea}{" "}
                                            square meters
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained" color="primary">
                                        Calculate
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Paper>
            </Grid>

            {tableData && (
                <Grid item xs={12} md={6} lg={4}>
                    <TableContainer component={Paper} sx={{ borderRadius: "8px" }}>
                        <Table aria-label="dynamic table">
                            <TableHead
                                sx={{
                                    backgroundColor: "#2a3a5b",
                                }}
                            >
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" sx={{ color: "white" }}>
                                            {formData.filterModel}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" sx={{ color: "white" }}>
                                            Value (m)
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.label}</TableCell>
                                        <TableCell>{tableData[row.key]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <XtrCalculatorImageDisplayer
                        filterModel={formData.filterModel}
                        baseType={formData.baseType}
                    />
                </Grid>
            )}
            {showMusicTable && resultData && (
                <Grid item xs={12} md={6} lg={4}>
                    <TableContainer component={Paper} sx={{ borderRadius: "8px" }}>
                        <Table aria-label="music inputs table">
                            <TableHead sx={{ backgroundColor: "#FFD700" }}>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography variant="h6">
                                            MUSIC INPUTS - XtreamFilter Vault Node
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Inlet Properties */}
                                <TableRow sx={{ backgroundColor: "#2a3a5b", color: "white" }}>
                                    <TableCell colSpan={2}>
                                        <Typography variant="subtitle1" sx={{ color: "white" }}>
                                            Inlet Properties
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Low Flow bypass (cu.m/s)</TableCell>
                                    <TableCell>{hardcodedData.lowFlowBypass}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>High Flow bypass (cu.m/s)</TableCell>
                                    <TableCell>{hardcodedData.highFlowBypass}</TableCell>
                                </TableRow>

                                {/* Storage Properties */}
                                <TableRow sx={{ backgroundColor: "#2a3a5b", color: "white" }}>
                                    <TableCell colSpan={2}>
                                        <Typography variant="subtitle1" sx={{ color: "white" }}>
                                            Storage Properties
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Surface Area (sq.m)</TableCell>
                                    <TableCell>{resultData?.surfaceArea || "Loading..."}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Extended Detention Depth (m)</TableCell>
                                    <TableCell>
                                        {resultData?.extendedDetentionDepth || "Loading..."}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Exfiltration Rate (mm/hr)</TableCell>
                                    <TableCell>{hardcodedData.exfiltrationRate}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Evaporative Loss as % of PET</TableCell>
                                    <TableCell>{hardcodedData.evaporativeLoss}</TableCell>
                                </TableRow>

                                {/* Outlet Properties */}
                                <TableRow sx={{ backgroundColor: "#2a3a5b", color: "white" }}>
                                    <TableCell colSpan={2}>
                                        <Typography variant="subtitle1" sx={{ color: "white" }}>
                                            Outlet Properties
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Equivalent Pipe Diameter (mm)</TableCell>
                                    <TableCell>
                                        {resultData?.equivalentPipeDiameter || "Loading..."}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Overflow Weir Width (m)</TableCell>
                                    <TableCell>{hardcodedData.weirWidth}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
};

export default XtrCalculator;
