// routes/AppRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../Pages/homePage";
import AboutPage from "../Pages/aboutPage";
import PlayPage from "../Pages/playPage";
import SignupPage from "../Pages/signupPage";
import DashBoardPage from "../Pages/dashBoardPage";
import EmailConfirmationPage from "../Pages/emailConfirmationPage";
import XtrCalculatorPage from "../Pages/xtrCalculatorPage";
import CaseStudiesPage from "../Pages/caseStudiesPage/caseStudiesPage";
import { AuthProvider } from "../Helpers/auth-context";
import ProtectedRoute from "./ProtectedRoute";
import EmailVerificationPage from "../Pages/emailVerificationPage";
import PleaseCheckYourEmailPage from "../Pages/pleaseCheckYourEmailPage";

function AppRoutes() {
    return (
        <AuthProvider>
            <Routes>
                <Route path="/about" element={<AboutPage />} />
                <Route path="/play" element={<PlayPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute>
                            <DashBoardPage />
                        </ProtectedRoute>
                    }
                />
                <Route path="/case-studies" element={<CaseStudiesPage />} />
                <Route path="/verify-email" element={<EmailVerificationPage />} />
                <Route path="/check-your-email" element={<PleaseCheckYourEmailPage />} />
                <Route path="/xtr" element={<HomePage />} />
                <Route path="/confirmation-success" element={<EmailConfirmationPage />} />
                <Route path="/" element={<XtrCalculatorPage />} />
                <Route path="*" element={<h1>404 Not Found</h1>} />

                {/* <Route path="/contact" element={<Contact />} /> */}
            </Routes>
        </AuthProvider>
    );
}

export default AppRoutes;
