import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box, Typography, Button } from "@mui/material";

const PleaseCheckYourEmailPage = () => {
    const navigate = useNavigate();
    const [secondsLeft, setSecondsLeft] = useState(4); // Set initial countdown to 4 seconds

    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsLeft((prevSeconds) => prevSeconds - 1);
        }, 1000);

        const timer = setTimeout(() => {
            navigate("/"); // Redirect after countdown reaches 0
        }, 4000);

        return () => {
            clearInterval(interval); // Clear interval on component unmount
            clearTimeout(timer); // Clear timeout on component unmount
        };
    }, [navigate]);

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    textAlign: "center",
                    marginTop: "80px",
                    padding: "20px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                }}
            >
                <Typography variant="h3" component="h1" gutterBottom>
                    Account Created Successfully!
                </Typography>
                <Typography
                    variant="h6"
                    color="textSecondary"
                    paragraph
                    sx={{ fontSize: "20px" }} // Customize text size
                >
                    We have sent a confirmation link to your email address. Please check your email to complete the
                    signup process.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "16px" }}>
                    Redirecting in {secondsLeft} seconds...
                </Typography>
                <Button variant="contained" color="primary" onClick={() => navigate("/")} sx={{ mt: 2 }}>
                    Go to Homepage
                </Button>
            </Box>
        </Container>
    );
};

export default PleaseCheckYourEmailPage;
