import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../Helpers/auth-context";
const ProtectedRoute = ({ children }) => {
    const { state } = useContext(AuthContext);

    if (!state.isAuthenticated) {
        return <Navigate to="/" />;
    }

    if (!state.emailVerified) {
        return <Navigate to="/verify-email" />;
    }

    return children;
};

export default ProtectedRoute;
