import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade, Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { Typography } from "@mui/material";
import "../styles/HeroSwiper.css"; // Create this CSS file in the same directory

const HeroSwiper = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const slides = [
        {
            image: "/imgs/20240327_080904-scaled-1.jpg",
            title: "Made For Innovation",
            subtitle: "SPECIFYING PROTECTOR®",
        },
        {
            image: "/imgs/union1-scaled-1.jpg",
            title: "Made For Innovation",
            subtitle: "SPECIFYING PROTECTOR®",
        },
        {
            image: "/imgs/Lightsveiw-Village.jpg",
            title: "Made For Innovation",
            subtitle: "SPECIFYING PROTECTOR®",
        },
        {
            image: "/imgs/shutterstock_2089801057-scaled-1.jpg",
            title: "SPECIFYING PROTECTOR",
            subtitle: "SPECIFYING PROTECTOR®",
        },
        {
            image: "/imgs/shutterstock_1009287067-2-scaled-1.jpg",
            title: "SPECIFYING PROTECTOR",
            subtitle: "SPECIFYING PROTECTOR®",
        },
        // Add more slides as needed
    ];

    return (
        <Swiper
            modules={[Pagination, EffectFade, Autoplay, Navigation]}
            spaceBetween={0}
            slidesPerView={1}
            effect="flip"
            pagination={{ clickable: true }}
            autoplay={{ delay: 6500 }}
            navigation
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            className="hero-swiper"
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    <div className="slide" style={{ backgroundImage: `url(${slide.image})` }}>
                        <div
                            className={
                                index === 3 || index === 4
                                    ? "slide-content-with-button"
                                    : "slide-content"
                            }
                        >
                            <Typography variant="h4">{slide.subtitle}</Typography>
                            <Typography variant="h2">{slide.title}</Typography>
                            {(index === 3 || index === 4) && <button>Click Here</button>}
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default HeroSwiper;
