import React, { useState } from "react";
import axios from "axios";

const EmailVerificationPage = () => {
    const [message, setMessage] = useState("");

    const handleResendVerificationEmail = async () => {
        try {
            const response = await axios.post("/api/auth/resend-verification-email");
            if (response.status === 200) {
                setMessage("Verification email sent successfully!");
            }
        } catch (error) {
            setMessage("Failed to send verification email.");
        }
    };

    return (
        <div>
            <h1>Email Verification Required</h1>
            <p>Please verify your email to access your dashboard.</p>
            <button onClick={handleResendVerificationEmail}>Resend Verification Email</button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default EmailVerificationPage;
