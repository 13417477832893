import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import IconMenu from "./IconMenu";

const ExpandableBox = ({
    title,
    backgroundColor,
    expanded,
    onClick,
    icons,
    iconTitles,
    menuItemTitles = [],
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIcon, setSelectedIcon] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event, index) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedIcon(index);
    };

    const handleMenuClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
        setSelectedIcon(null);
    };

    return (
        <Box
            onClick={onClick}
            sx={{
                backgroundColor: backgroundColor,
                color: "#fff",
                width: expanded ? "300px" : "120px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 10px",
                cursor: "pointer",
                transition: "width 0.3s ease",
                overflow: "hidden",
                borderRadius: "5px",
            }}
        >
            <Typography>{title}</Typography>
            {expanded && (
                <Box
                    sx={{ display: "flex", gap: "1px", marginLeft: "auto", alignItems: "stretch" }}
                >
                    {icons.map((icon, index) => (
                        <React.Fragment key={index}>
                            <IconButton
                                sx={{ color: "#fff", height: "100%" }}
                                onClick={(event) => handleMenuClick(event, index)}
                            >
                                {icon}
                            </IconButton>
                            <IconMenu
                                anchorEl={anchorEl}
                                open={selectedIcon === index && open}
                                onClose={handleMenuClose}
                                backgroundColor={backgroundColor}
                                icon={icon}
                                iconTitle={iconTitles[index]}
                                title={title}
                            />
                        </React.Fragment>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default ExpandableBox;
