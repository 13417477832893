import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

const EmailConfirmationPage = () => {
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const verificationMessage = searchParams.get("message");
        const token = searchParams.get("token");

        if (verificationMessage) {
            setMessage(decodeURIComponent(verificationMessage));
            setIsSuccess(true);
        } else {
            setMessage("Email confirmation failed. No verification message received.");
            setIsSuccess(false);
        }

        if (token) {
            localStorage.setItem("authToken", token);
        } else {
            console.warn("No authentication token received.");
        }
    }, [searchParams]);

    const handleContinue = () => {
        // Redirect to home page or dashboard
        navigate("/");
    };

    return (
        <div style={styles.container}>
            <div style={isSuccess ? styles.successCard : styles.errorCard}>
                <h2 style={styles.heading}>{isSuccess ? "Success!" : "Oops!"}</h2>
                <p style={styles.message}>{message}</p>
                {isSuccess && (
                    <button style={styles.button} onClick={handleContinue}>
                        Continue to Dashboard
                    </button>
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
    },
    successCard: {
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        padding: "20px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        borderTop: "4px solid #4CAF50",
    },
    errorCard: {
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        padding: "20px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        borderTop: "4px solid #f44336",
    },
    heading: {
        color: "#333",
        marginBottom: "10px",
    },
    message: {
        color: "#666",
        marginBottom: "20px",
    },
    button: {
        backgroundColor: "#4CAF50",
        color: "white",
        padding: "10px 15px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "16px",
    },
};

export default EmailConfirmationPage;
