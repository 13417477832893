import React, { useState } from "react";
import { Box, TextField, Button, Typography, Snackbar, Alert } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import apiClient from "../Helpers/apiClient";

function SignUpForm() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiClient.post("/api/Account/register", {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                password: formData.password,
            });

            // Extract user data from the response
            const { token, email, firstName, lastName } = response.data;

            setSnackbar({
                open: true,
                message: "User registered successfully",
                severity: "success",
            });

            // Redirect to the dashboard page after successful registration
            setTimeout(() => {
                // navigate("/dashboard");
                navigate("/check-your-email");
            }, 1000); // Delay for 2 seconds so the user can see the success message
        } catch (error) {
            console.log("Registration failed", error);
            let errorMessage = "An error occurred. Please try again.";
            if (error.response && Array.isArray(error.response.data)) {
                // Extract the descriptions from the error array
                errorMessage = error.response.data.map((err) => err.description).join(" "); // Join multiple error messages if there are more
            }

            setSnackbar({
                open: true,
                message: "Registration failed: " + (errorMessage || error.message),
                severity: "error",
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                width: "350px",
                backgroundColor: "#2d3b56",
                borderRadius: "10px",
                padding: "20px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                margin: "0 auto",
            }}
        >
            <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                <AccountCircleIcon sx={{ fontSize: 40, color: "#fff" }} />
                <Typography variant="h6" sx={{ color: "#fff" }}>
                    Sign Up
                </Typography>
            </Box>

            {["firstName", "lastName", "email", "password"].map((field) => (
                <React.Fragment key={field}>
                    <Typography variant="body2" sx={{ color: "#fff", marginBottom: "8px" }}>
                        {field.charAt(0).toUpperCase() + field.slice(1)}
                    </Typography>
                    <TextField
                        fullWidth
                        name={field}
                        type={field === "password" ? "password" : "text"}
                        variant="outlined"
                        value={formData[field]}
                        onChange={handleChange}
                        InputProps={{
                            sx: {
                                backgroundColor: "#fff",
                                color: "#000",
                                height: "40px",
                                padding: "0",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#58627b",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#8da0b3",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#fff",
                                },
                            },
                        }}
                        sx={{ marginBottom: "15px" }}
                    />
                </React.Fragment>
            ))}

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ backgroundColor: "#58627b", color: "#fff", marginBottom: "15px" }}
            >
                Sign Up
            </Button>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
                severity={snackbar.severity}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant="filled">
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default SignUpForm;
