import React from "react";
import { Menu, MenuItem, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const IconMenu = ({
    anchorEl,
    open,
    onClose,
    backgroundColor,
    icon,
    iconTitle,
    onMenuItemClick,
    title,
}) => {
    // Safely handle the event in the onClose function
    const handleClose = (event) => {
        if (onClose) {
            onClose(event); // Pass event to onClose
        }
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose} // Always use the event-safe version
            MenuListProps={{
                "aria-labelledby": "icon-button",
                sx: {
                    backgroundColor: backgroundColor,
                    color: "#fff",
                    width: "280px",
                    borderRadius: "5px",
                },
            }}
        >
            {title === "EcoProtector" && iconTitle === "Drawing Design Tool" ? (
                <Box
                    component="a"
                    href={process.env.REACT_APP_API_URL}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none", // Remove underline from link styling
                        color: "inherit", // Inherit color to maintain style
                        cursor: "pointer", // Pointer cursor to indicate clickability
                    }}
                >
                    {icon}
                    <Typography variant="h6" sx={{ padding: "10px" }}>
                        {iconTitle}
                    </Typography>
                </Box>
            ) : title === "XtreamProtector" && iconTitle === "Xtream Calculator" ? (
                <Box
                    component="a"
                    href="/xtr"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none", // Remove underline from link styling
                        color: "inherit", // Inherit color to maintain style
                        cursor: "pointer", // Pointer cursor to indicate clickability
                    }}
                >
                    {icon}
                    <Typography variant="h6" sx={{ padding: "10px" }}>
                        {iconTitle}
                    </Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {icon}
                    <Typography variant="h6" sx={{ padding: "10px" }}>
                        {iconTitle}
                    </Typography>
                </Box>
            )}
            <Box>
                {iconTitle === "Product Overview" ? (
                    <>
                        <MenuItem onClick={handleClose}>My EcoProtector Projects</MenuItem>
                    </>
                ) : iconTitle === "Installation Data" ? (
                    <>
                        <MenuItem
                            onClick={() => {
                                window.open(
                                    "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/EcoProtector+Installation.pdf",
                                    "_blank"
                                );
                            }}
                        >
                            Installation Manual
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                window.open(
                                    "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/EcoProtector+Maintenance.pdf",
                                    "_blank"
                                );
                            }}
                        >
                            Maintenance Manual
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                window.open(
                                    "https://jweoigjwiojjr.s3.ap-southeast-2.amazonaws.com/EcoProtector+GPT+-+Maintenance+Register.pdf",
                                    "_blank"
                                );
                            }}
                        >
                            Maintenance Register
                        </MenuItem>
                    </>
                ) : iconTitle === "Case Studies" ? (
                    <>
                        <MenuItem
                            component={Link}
                            to="/case-studies" // Use the React Router Link for internal navigation
                        >
                            Case Studies
                        </MenuItem>
                    </>
                ) : title === "EcoProtector" && iconTitle === "Drawing Design Tool" ? (
                    <></>
                ) : title === "XtreamProtector" && iconTitle === "Xtream Calculator" ? (
                    <></>
                ) : (
                    <>
                        <MenuItem onClick={handleClose}>Option 1</MenuItem>
                        <MenuItem onClick={handleClose}>Option 2</MenuItem>
                        <MenuItem onClick={handleClose}>Option 3</MenuItem>
                    </>
                )}
            </Box>
        </Menu>
    );
};

export default IconMenu;
