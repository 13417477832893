// App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes"; // Importing the routes

function App() {
    return (
        <Router>
            {/* The routing logic is encapsulated in a separate file */}
            <AppRoutes />
        </Router>
    );
}

export default App;
