import axios from "axios";

// Create the Axios client


const apiUrl = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
    baseURL: apiUrl, // Replace with your EC2 instance public IP or domain
    // baseURL: "http://testspecifyer.ap-southeast-2.elasticbeanstalk.com/", // Replace with your EC2 instance public IP or domain
    // "http://localhost:5186/api/calculator/calculate",
    // http://your-ec2-public-ip/api

    withCredentials: true,
});

// Export the apiClient as the default export
export default apiClient;
