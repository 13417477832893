import { Box, Grid } from "@mui/material";
import React from "react";
const XTR300DWV = "/imgs/XTR.300&DWV.png";
const XTR300FalseFloor = "/imgs/XTR.300&FALSE_FLOOR.png";
const XTR500DWV = "/imgs/XTR.500&DWV.png";
const XTR500FalseFloor = "/imgs/XTR.500&FALSE_FLOOR.png";

const XtrCalculatorImageDisplayer = ({ filterModel, baseType }) => {
    let selectedImage;

    // Conditional logic based on filterModel and baseType
    if (filterModel === "XTR.300" && baseType === "DWV") {
        selectedImage = XTR300DWV;
    } else if (filterModel === "XTR.300" && baseType === "FRP") {
        selectedImage = XTR300FalseFloor;
    } else if (filterModel === "XTR.500" && baseType === "DWV") {
        selectedImage = XTR500DWV;
    } else if (filterModel === "XTR.500" && baseType === "FRP") {
        selectedImage = XTR500FalseFloor;
    } else {
        selectedImage = null; // Default to no image if none match
    }

    return (
        <Box paddingTop={"2vh"}>
            {selectedImage && (
                <img
                    src={selectedImage}
                    style={{ width: "100%", height: "auto", maxWidth: "100%" }} // Ensure the image fits the container
                    alt={`${filterModel} with ${baseType}`}
                />
            )}
        </Box>
    );
};

export default XtrCalculatorImageDisplayer;
